import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import burgerClosed from '../../images/icon-burger-closed.svg';
import burgerOpened from '../../images/icon-burger-opened.svg';
import { Box } from '../elements';

const BgNav = styled.ul`
  position: fixed;
  padding: 20% 0;
  width: 100vw;
  height: calc(100vh - 90px);
  top: 90px;
  right: ${({ isOpen }) => (isOpen ? `${0}` : `${-100}vw`)};
  z-index: 10;
  background: ${({ theme }) => theme.colors.primary};
  transition: right 0.3s ease-in-out;

  li {
    text-align: left;
    min-width: 200px;
    margin: 10px 0;
  }

  .link {
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes[4]}px;
    font-family: ${({ theme }) => theme.fonts.secondary};
    text-transform: uppercase;

    &-plain {
      display: block;
      color: inherit;
      padding: 10px;
      font-size: 20px;
    }

    &-active {
      font-size: 29px;
      color: inherit;
      text-shadow: 8px 3px 4px ${({ theme }) => theme.colors.primary};
      border-bottom: 1px solid white;

      &::after {
        content: '';
        width: 10px;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 50% 0;
    position: static;
    display: block;
    height: inherit;
    width: inherit;
  }
`;

const ButtonsContainer = styled(Box)`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);

  button {
    width: 80%;
    height: 80%;

    img {
      width: 100%;
      height: 100%;
    }
  }
`;

const isActive = ({ href, location }) => {
  const isCurrent =
    href.replace(/\//g, '') === location.pathname.replace(/\//g, '');

  return isCurrent ? { className: 'link-active' } : {};
};

export const Navigation = ({ navigation, bgLocalFile }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <ButtonsContainer>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          style={{ display: `${isOpen ? 'none' : 'block'}` }}
        >
          <img alt="" src={burgerClosed} />
        </button>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          style={{ display: `${isOpen ? 'block' : 'none'}` }}
        >
          <img alt="" src={burgerOpened} />
        </button>
      </ButtonsContainer>
      <BgNav bgLocalFile={bgLocalFile} isOpen={isOpen}>
        {navigation.map(el => (
          <li key={shortid.generate()} className="link">
            <Link
              to={el.primary.nav_link}
              getProps={isActive}
              className="link-plain"
            >
              {el.primary.nav_title}
            </Link>
          </li>
        ))}
      </BgNav>
    </>
  );
};

Navigation.propTypes = {
  navigation: PropTypes.array.isRequired,
  bgLocalFile: PropTypes.object.isRequired
};
