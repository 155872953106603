import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../components/elements';

export const RichText = ({ raw }) => {
  const { text } = raw;
  let { type } = raw;
  switch (type) {
    case 'heading1':
      type = 'h1';
      break;

    case 'heading2':
      type = 'h2';
      break;

    case 'heading3':
      type = 'h3';
      break;

    case 'heading4':
      type = 'h4';
      break;

    default:
      type = 'p';
  }
  return (
    <Text as={type} pb={3}>
      {text}
    </Text>
  );
};

RichText.propTypes = {
  raw: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};
