import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { graphql, Link } from 'gatsby';
import { Navigation } from './Navigation';
import { Flex, Image } from '../elements';

const PageWrapper = styled(Flex)`
  position: relative;
  min-height: 100vh;
  background: rgb(199, 209, 209);
  background: linear-gradient(
    90deg,
    rgba(199, 209, 209, 1) 0%,
    rgba(233, 255, 239, 1) 100%
  );
  overflow: hidden;
`;

const Header = styled(Flex)`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.background.header};
  z-index: 3;
`;

export const Layout = ({ navigationPack, siteLogo, children }) => (
  <>
    <Header
      flexDirection="column"
      height={{ _: 90, md: '100%' }}
      width={{ _: 1, md: 'auto' }}
      p="10px"
    >
      <Link to="/" style={{ display: 'flex' }}>
        <Image
          minWidth={{ _: '85px', md: '100px' }}
          minHeight={{ _: '85px', md: '100px' }}
          backgroundImage={`url(${siteLogo.localFile.url})`}
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          m="0 auto 10px"
          style={{
            position: 'relative',
            boxShadow: '4px 5px 10px 0px',
            borderRadius: '50%',
            zIndex: '11'
          }}
        />
      </Link>
      <Navigation
        navigation={navigationPack.navigation}
        bgLocalFile={navigationPack.bgLocalFile}
        bgAlt={navigationPack.bgAlt}
      />
    </Header>
    <PageWrapper
      m={{ _: '0 auto', md: '0 0 0 200px' }}
      pt={{ _: '90px', md: '0' }}
    >
      {children}
    </PageWrapper>
  </>
);

Layout.propTypes = {
  navigationPack: PropTypes.object.isRequired,
  siteLogo: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export const query = graphql`
  fragment LayoutFragment on Query {
    prismicNavigation {
      data {
        body {
          primary {
            nav_link
            nav_title
          }
        }
        site_logo {
          alt
          localFile {
            url
          }
        }
        bg_nav {
          localFile {
            url
          }
        }
      }
    }
  }
`;
